<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import SolicitudService from '../../services/solicitud.service'
import EmpleadoService from '../../services/empleado.service'
import SoporteService from '../../services/soporte.service'
import ModalAgregarSoporte from '../components/modalAgregarSoporte.vue'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import Icon from '@/components/Icon/index.vue'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import Swal from 'sweetalert2'
import { error, messageConfirmSave, warning } from '../../../../../../libs/mensajes'
import { useRouter, useRoute } from 'vue-router'
dayjs.extend(isSameOrAfter)

const store = useStore()
const router = useRouter()
const route = useRoute()
const _SolicitudService = ref(new SolicitudService())
const _EmpleadoService = ref(new EmpleadoService())
const _SoporteService = ref(new SoporteService())
const listMotivos = ref([])
const supports = ref([])
const modalAgregarSoporte = ref()
const listTipoEmpleado = ref(['TRABAJADOR EN MISION', 'TRABAJADOR DE PLANTA'])
const listTipoTiempo = ref(['MEDIO TIEMPO', 'TIEMPO COMPLETO'])
const infoEmpleado = ref({
  nombre: '',
  cedula: '',
  area: '',
  cargo: ''
})

// Validar si es edición
const isEdit = computed(() => {
  return !!route.params.id
})

// Obtener la información del usuario
const getUser = computed(() => {
  return store.getters['auth/user']
})

const validationSchema = yup.object({
  fechaSolicitud: yup.string().required('La fecha de solicitud es requerida').label(''),
  tipoEmpleado: yup.string().required('El tipo de trabajador es requerido').label(''),
  idMotivo: yup.string().required('El motivo es requerido').label(''),
  nombre: yup.string().required('El nombre es requerido').label(''),
  cedula: yup.string().required('La cédula es requerida').label(''),
  idArea: yup.string().required('El área es requerida').label(''),
  idCargo: yup.string().required('El cargo es requerido').label(''),
  observacionMotivo: yup.string().required('La observación del motivo es requerida').label(''),
  /* observacionMotivo: yup.string().when('nombreMotivo', {
    is: (val) => val === 'OTROS',
    then: yup.string().required('La observación es requerida si el motivo es otros').label('')
  }), */
  fechaInicial: yup.string().required('La fecha inicial es requerida').label(''),
  fechaFinal: yup.string().required('La fecha final es requerida').label(''),
  horaInicial: yup.string().required('La hora inicial es requerida').label(''),
  // Validar que la hora final no sea menor a la hora inicial
  horaFinal: yup
  .string()
  .required('La hora final es requerida')
  .test(
    'is-greater',
    'La hora final no puede ser menor a la hora inicial',
    (value, context) => {
      const [horaIni, minIni] = context.parent.horaInicial.split(':').map(Number)
      const [horaFin, minFin] = value.split(':').map(Number)

      return !(horaFin < horaIni || (horaFin === horaIni && minFin < minIni))
    }
  ),
  tipoTiempo: yup.string().required('El tipo de tiempo es requerido').label(''),
  horasTotal: yup.string().required('Las horas totales son requeridas').label(''),
  minutosTotal: yup.string().required('Los minutos totales son requeridos').label('')
})

const { errors, handleSubmit, values: model } = useForm({ validationSchema })

useField('fechaSolicitud', null, { initialValue: dayjs().format('YYYY-MM-DD') })
useField('tipoEmpleado', null, { initialValue: '' })
useField('idMotivo', null, { initialValue: '' })
useField('nombreMotivo', null, { initialValue: '' })
useField('nombre', null, { initialValue: '' })
useField('cedula', null, { initialValue: '' })
useField('idArea', null, { initialValue: '' })
useField('nombreArea', null, { initialValue: '' })
useField('idCargo', null, { initialValue: '' })
useField('nombreCargo', null, { initialValue: '' })
useField('observacionMotivo', null, { initialValue: '' })
useField('fechaInicial', null, { initialValue: '' })
useField('fechaFinal', null, { initialValue: '' })
useField('horaInicial', null, { initialValue: '' })
useField('horaFinal', null, { initialValue: '' })
useField('tipoTiempo', null, { initialValue: '' })
useField('horasTotal', null, { initialValue: '' })
useField('minutosTotal', null, { initialValue: '' })

// Envió de la información
const onSubmit = handleSubmit(async (values) => {
  if (validateFechaFinal(values.fechaInicial, values.fechaFinal)) {
    return
  }

  if (validateHoraFinal(values.horaInicial, values.horaFinal)) {
    return
  }

  if (validateMinutosTotal(values.minutosTotal)) {
    return
  }

  for (const [index, item] of Object.entries(model)) {
    if (typeof item === 'string') {
      model[index] = item.toUpperCase()
    }
  }

  // Generar un valor para el campo tiempoTotal qué sería la suma de las horas y minutos totales
  model.tiempoTotal = `${model.horasTotal}:${model.minutosTotal}`

  if (isEdit.value) {
    const { id } = route.params

    messageConfirmSave('', 'Información', 'warning', '¿Esta seguro de editar la solicitud de permiso?').then(({ isConfirmed }) => {
      if (isConfirmed) {
        // Remover horasTotal y minutosTotal del modelo
        delete model.horasTotal
        delete model.minutosTotal

        _SolicitudService.value.editarSolicitud(id, model).then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'La solicitud de permiso se ha editado correctamente'
          }).then(() => {
            router.push({ name: 'pharmasan.administrativa.solicitud-permisos.listado' })
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  } else {
    const htmlMensaje = `
      <p class="font-sm text-xl font-semibold my-4">
        ¿Está seguro de crear la solicitud de permiso?
      </p>
      <p>
        <span class="font-bold text-xs text-red-700">Importante: </span>
        <span class="text-xs">
          Le solicitamos su cumplimiento con el tiempo solicitado y autorizado. Los permisos para citas medicas se dan por tres horas. Tiempos adicionales a los solicitados, podrán ser descontados según criterio de la empresa.
          Autorizo a LA EMPRESA para que descuente el tiempo de mi salario, en evento de tratarse permiso no remunerado. Art. 57 CST Numeral 6 y RIT, Art. 150 CST descuentos permitidos.
        </span>
      </p>
      <p class="mt-4">
        <span class="font-bold text-xs">Nota: </span>
        <span class="text-xs">
          Si requiere adjuntar soportes, puede realizarlo en el listado de solicitudes en el editar de la solicitud.
      </p>
    `

    messageConfirmSave('', 'Información', 'warning', htmlMensaje).then(({ isConfirmed }) => {
      if (isConfirmed) {
        // Remover horasTotal y minutosTotal del modelo
        delete model.horasTotal
        delete model.minutosTotal

        _SolicitudService.value.crearSolicitud(model).then(({ data }) => {
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: `La solicitud de permiso #${data.id} se ha creado correctamente`
          }).then(() => {
            router.push({ name: 'pharmasan.administrativa.solicitud-permisos.listado' })
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  }
})

// Validar que la fecha final no sea menor a la fecha inicial
const validateFechaFinal = (fechaInicial, fechaFinal) => {
  const fecIni = dayjs(fechaInicial)
  const fecFin = dayjs(fechaFinal)
  if (fecFin.isBefore(fecIni)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'La fecha final no puede ser menor a la fecha inicial'
    })
    errors.fechaFinal = 'La fecha final no puede ser menor a la fecha inicial'
    return true
  } else {
    errors.fechaFinal = ''
    return false
  }
}

// Validar que la hora final no sea menor a la hora inicial
const validateHoraFinal = (horaInicial, horaFinal) => {
  const formatoHora = 'HH:mm' // Formato de hora

  const horIni = dayjs(horaInicial, formatoHora)
  const horFin = dayjs(horaFinal, formatoHora)

  if (horFin.isBefore(horIni)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'La hora final no puede ser menor a la hora inicial'
    })
    errors.horaFinal = 'La hora final no puede ser menor a la hora inicial'
    return true
  } else {
    errors.horaFinal = ''
    return false
  }
}

// Validar que los minutos totales no sean mayor a 59
const validateMinutosTotal = (minutosTotal) => {
  if (minutosTotal > 59) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'Los minutos totales no pueden ser mayor a 59'
    })
    errors.minutosTotal = 'Los minutos totales no pueden ser mayor a 59'
    return true
  } else {
    errors.minutosTotal = ''
    return false
  }
}

// Obtener el listado de los motivos
const getListMotivos = async () => {
  await _SolicitudService.value.listarMotivos().then((res) => {
    listMotivos.value = res.data
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Cuando se selecciona un motivo del dropdown seteamos el ID y el nombre
const onMotivoChange = (e) => {
  const motivo = listMotivos.value.filter(mot => mot.id === e.value)[0]
  model.idMotivo = motivo.id
  model.nombreMotivo = motivo.nombre
}

// Obtener información del empleado
const getInfoEmpleado = async (userId) => {
  if (getUser.value) {
    await _EmpleadoService.value.getEmpleado(userId).then((res) => {
      infoEmpleado.value = res.data

      model.nombre = infoEmpleado.value.nombre
      model.cedula = infoEmpleado.value.cedula
      model.idArea = infoEmpleado.value.areaId
      model.nombreArea = infoEmpleado.value.area
      model.idCargo = infoEmpleado.value.cargoId
      model.nombreCargo = infoEmpleado.value.cargo
    }).catch((err) => {
      const { errorType, info } = err.response.data
      if (errorType === 'NotFoundCargo') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `
            <p>El empleado <span class="font-semibold">${info.full_name}</span>
            con cédula <span class="font-semibold">${info.cedula}</span>
            no tiene un cargo asignado.</p>

            <p class="mt-2">Por favor comuníquese con el área de recursos humanos.</p>
          `
        }).then(() => {
          router.push({ name: 'pharmasan.administrativa.solicitud-permisos.listado' })
        })
      }
    })
  }
}

// Obtener la informacion de la solicitud para editar
const getInfoSolicitud = async (id) => {
  await _SolicitudService.value.getSolicitud(id).then(({ status, data: response }) => {
    if (status !== 200) return

    // Si el valor es null mostrar swal de error y redireccionar al listado
    if (!response) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'La solicitud de permiso no existe'
      }).then(() => {
        router.push({ name: 'pharmasan.administrativa.solicitud-permisos.listado' })
      })
    } else {
      model.fechaSolicitud = response.fechaSolicitud
      model.tipoEmpleado = response.tipoEmpleado
      model.idMotivo = response.idMotivo
      model.nombreMotivo = response.nombreMotivo
      model.nombre = response.nombre
      model.cedula = response.cedula
      model.idArea = response.idArea
      model.nombreArea = response.nombreArea
      model.idCargo = response.idCargo
      model.nombreCargo = response.nombreCargo
      model.observacionMotivo = response.observacionMotivo
      model.fechaInicial = response.fechaInicial
      model.fechaFinal = response.fechaFinal
      model.horaInicial = response.horaInicial
      model.horaFinal = response.horaFinal
      model.tipoTiempo = response.tipoTiempo

      // Separar el tiempo total en horas y minutos
      const [horasTotal, minutosTotal] = response.tiempoTotal.split(':')
      model.horasTotal = horasTotal
      model.minutosTotal = minutosTotal
    }
  }).catch((err) => {
    console.log('err', err)
  })
}

// Funcion para obtener los soportes de la solicitud
const fetchSupportsBySolicitud = async (solicitudId) => {
  await _SoporteService.value.listarSoportesPorSolicitud(solicitudId).then(({ status, data: response }) => {
    if (status !== 200) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Problemas al listar los soportes de la solicitud, consulte con el administrador del sistema'
      })
    } else {
      supports.value = response
    }
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

/* Visualizar o descargar soportes */
const verSoporte = async (adjunto) => {
  await _SoporteService.value.descargarSoporte(adjunto).then((url) => {
    if (adjunto !== null) {
      window.open(url, '_blank')
    } else {
      warning('No hay soporte')
    }
  }).catch((err) => {
    console.error(err.message)
    error(err.response.data.message)
  })
}

// Funcion para abrir el modal de agregar soportes
const onModalAgregarSoporte = () => {
  const { id } = route.params

  modalAgregarSoporte.value.openModal(id)
}

// Hook onMounted
onMounted(async () => {
  const userId = getUser.value.user_id
  if (userId) {
    await getListMotivos()
    await getInfoEmpleado(userId)
  }

  if (isEdit.value) {
    const { id } = route.params
    await getInfoSolicitud(id)
    await fetchSupportsBySolicitud(id)
  }
})

</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <!-- Titulo Card -->
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-lg mr-auto" :class="isEdit ? 'text-yellow-600' : 'text-green-800'">{{ isEdit ? 'Editar Solicitud de Permiso' : 'Nueva Solicitud de Permiso' }}</h2>
        </div>
        <!-- Body Card -->
        <div class="p-4">
          <!-- Información del empleado -->
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 20px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-base font-semibold">Información del Empleado</span>
          </Divider>
          <div class="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-danger mr-5">
                <span class="symbol-label">
                  <Icon
                    icon="clarity:employee-solid"
                    class="text-2xl"
                  />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Nombre:</span>
                <span class="text-gray-700 text-sm">{{ isEdit ? model.nombre : infoEmpleado.nombre }}</span>
                <MessageError :text="errors.nombre" />
              </div>
            </div>
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-primary mr-5">
                <span class="symbol-label">
                  <Icon
                    icon="pepicons-print:identification"
                    class="text-3xl"
                  />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Cedula:</span>
                <span class="text-gray-700 text-sm">{{ isEdit ? model.cedula : infoEmpleado.cedula }}</span>
                <MessageError :text="errors.cedula" />
              </div>
            </div>
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-dark mr-5">
                <span class="symbol-label">
                  <Icon
                    icon="mdi:location"
                    class="text-3xl"
                  />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Área:</span>
                <span class="text-gray-700 text-sm">{{ isEdit ? model.nombreArea : infoEmpleado.area }}</span>
                <MessageError :text="errors.idArea" />
              </div>
            </div>
            <div class="col-span-1 flex">
              <div class="symbol symbol-50 symbol-light-warning mr-5">
                <span class="symbol-label">
                  <Icon
                    icon="eos-icons:job"
                    class="text-3xl"
                  />
                </span>
              </div>
              <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
                <span class="text-gray-500 text-xs">Cargo:</span>
                <span class="text-gray-700 text-sm">{{ isEdit ? model.nombreCargo : infoEmpleado.cargo }}</span>
                <MessageError :text="errors.idCargo" />
              </div>
            </div>
          </div>

          <!-- Información de la solicitud -->
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 30px 0 25px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-base font-semibold">Información General</span>
          </Divider>
          <div class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <!-- Fecha Solicitud -->
            <div class="sm:col-span-6">
              <label for="fechaSolicitud" class="block text-xs font-medium text-gray-700 mb-2">Fecha Solicitud:</label>
              <InputText
                id="fechaSolicitud"
                type="date"
                class="w-full"
                disabled
                :class="{ 'p-invalid': errors.fechaSolicitud }"
                v-model="model.fechaSolicitud"
                :pt="{
                  root: { style: 'height: 37px' }
                }"
              />
              <MessageError :text="errors.fechaSolicitud" />
            </div>
            <!-- Tipo trabajador -->
            <div class="sm:col-span-3">
              <label for="tipoEmpleado" class="block text-xs font-medium text-gray-700 mb-2">Tipo Trabajador:</label>
              <div class="card flex justify-content-center">
                <div class="flex flex-column gap-3">
                  <div v-for="tipoEmpleado in listTipoEmpleado" :key="tipoEmpleado" class="flex align-items-center">
                    <RadioButton
                      v-model="model.tipoEmpleado"
                      :inputId="tipoEmpleado"
                      name="tipoEmpleado"
                      :value="tipoEmpleado"
                      :class="{ 'p-invalid': errors.tipoEmpleado }"
                    />
                    <label :for="tipoEmpleado" class="ml-2">{{ tipoEmpleado }}</label>
                  </div>
                </div>
              </div>
              <MessageError :text="errors.tipoEmpleado" />
            </div>
            <!-- Tipo Tiempo -->
            <div class="sm:col-span-3">
              <label for="tipoTiempo" class="block text-xs font-medium text-gray-700 mb-2">Tipo Tiempo:</label>
              <div class="card flex justify-content-center">
                <div class="flex flex-column gap-3">
                  <div v-for="tipoTiempo in listTipoTiempo" :key="tipoTiempo" class="flex align-items-center">
                    <RadioButton
                      v-model="model.tipoTiempo"
                      :inputId="tipoTiempo"
                      name="tipoTiempo"
                      :value="tipoTiempo"
                      :class="{ 'p-invalid': errors.tipoTiempo }"
                    />
                    <label :for="tipoTiempo" class="ml-2">{{ tipoTiempo }}</label>
                  </div>
                </div>
              </div>
              <MessageError :text="errors.tipoTiempo" />
            </div>
            <!-- Motivo -->
            <div class="sm:col-span-6">
              <label for="motivo" class="block text-xs font-medium text-gray-700 mb-2">Motivo:</label>
              <Dropdown
                id="motivo"
                v-model="model.idMotivo"
                :options="listMotivos"
                optionLabel="nombre"
                optionValue="id"
                placeholder="Seleccione una opción"
                :filter="true"
                scrollHeight="auto"
                filterPlaceholder="Buscar por nombre"
                class="w-full"
                :class="{ 'p-invalid': errors.idMotivo }"
                @change="onMotivoChange"
              />
              <MessageError :text="errors.idMotivo" />
            </div>
            <!-- Motivo Observación -->
            <div class="sm:col-span-6">
              <label for="observacionMotivo" class="block text-xs font-medium text-gray-700 mb-2">Observación del motivo:</label>
              <Textarea
                id="observacionMotivo"
                v-model="model.observacionMotivo"
                rows="3"
                cols="30"
                class="w-full"
                :class="{ 'p-invalid': errors.observacionMotivo }"
              />
              <MessageError :text="errors.observacionMotivo" />
            </div>
            <!-- Fecha Inicial -->
            <div class="sm:col-span-3">
              <label for="fechaInicial" class="block text-xs font-medium text-gray-700 mb-2">Fecha Inicial:</label>
              <InputText
                id="fechaInicial"
                type="date"
                :min="model.fechaSolicitud"
                class="w-full"
                :class="{ 'p-invalid': errors.fechaInicial }"
                v-model="model.fechaInicial"
                :pt="{
                  root: { style: 'height: 37px' }
                }"
              />
              <MessageError :text="errors.fechaInicial" />
            </div>
            <!-- Fecha Final -->
            <div class="sm:col-span-3">
              <label for="fechaFinal" class="block text-xs font-medium text-gray-700 mb-2">Fecha Final:</label>
              <InputText
                id="fechaFinal"
                type="date"
                :min="model.fechaInicial"
                class="w-full"
                :class="{ 'p-invalid': errors.fechaFinal }"
                v-model="model.fechaFinal"
                :pt="{
                  root: { style: 'height: 37px' }
                }"
              />
              <MessageError :text="errors.fechaFinal" />
            </div>
            <!-- Hora Inicial -->
            <div class="sm:col-span-3">
              <label for="horaInicial" class="block text-xs font-medium text-gray-700 mb-2">Hora Inicial:</label>
              <InputText
                id="horaInicial"
                type="time"
                class="w-full"
                :class="{ 'p-invalid': errors.horaInicial }"
                v-model="model.horaInicial"
                :pt="{
                  root: { style: 'height: 37px' }
                }"
              />
              <MessageError :text="errors.horaInicial" />
            </div>
            <!-- Hora Final -->
            <div class="sm:col-span-3">
              <label for="horaFinal" class="block text-xs font-medium text-gray-700 mb-2">Hora Final:</label>
              <InputText
                id="horaFinal"
                type="time"
                :min="model.horaInicial"
                class="w-full"
                :class="{ 'p-invalid': errors.horaFinal }"
                v-model="model.horaFinal"
                :pt="{
                  root: { style: 'height: 37px' }
                }"
              />
              <MessageError :text="errors.horaFinal" />
            </div>
          </div>

          <!-- Información de la solicitud -->
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 30px 0 25px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-base font-semibold">Horas y minutos</span>
          </Divider>
          <div class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <!-- Hora Total -->
            <div class="sm:col-span-3">
              <label for="horasTotal" class="block text-xs font-medium text-gray-700 mb-2">Horas Total:</label>
              <InputText
                id="horasTotal"
                type="number"
                min="0"
                max="999"
                class="w-full"
                :class="{ 'p-invalid': errors.horasTotal }"
                v-model="model.horasTotal"
                :pt="{
                  root: { style: 'height: 37px' }
                }"
              />
              <MessageError :text="errors.horasTotal" />
            </div>
            <!-- Minutos Total -->
            <div class="sm:col-span-3">
              <label for="minutosTotal" class="block text-xs font-medium text-gray-700 mb-2">Minutos Total:</label>
              <InputText
                id="minutosTotal"
                type="number"
                min="0"
                max="59"
                class="w-full"
                :class="{ 'p-invalid': errors.minutosTotal }"
                v-model="model.minutosTotal"
                :pt="{
                  root: { style: 'height: 37px' }
                }"
              />
              <MessageError :text="errors.minutosTotal" />
            </div>
          </div>

          <!-- Información de los soportes -->
          <div v-if="isEdit">
            <div class="flex justify-between w-full my-6">
              <Divider
                align="left"
                type="dashed"
                :pt="{
                root: { style: 'margin: 0 !important; padding: 0 !important; flex: 1' },
                content: { style: 'padding: 0 5px 0 0 !important' }
              }"
              >
                <div class="flex justify-between">
                  <span class="text-base font-semibold">Soportes</span>
                </div>
              </Divider>
              <Button
                label="Agregar Soporte"
                severity="info"
                outlined
                size="small"
                icon="pi pi-plus"
                @click="onModalAgregarSoporte"
              />
            </div>

            <div class="col-span-12 flex">
              <div class="flex flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
                <div v-if="supports.length <= 0" class="flex flex-col justify-center items-center w-full">
                  <svg class="opacity-20" height="100px" width="100px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                       viewBox="0 0 512 512" xml:space="preserve">
<path style="fill:#FFFFFF;" d="M494.012,369.545c9.199,72.482-51.985,133.675-124.467,124.467
c-49.399-6.272-89.293-46.165-95.565-95.565c-9.19-72.474,51.994-133.658,124.476-124.459
C447.855,280.26,487.74,320.145,494.012,369.545z"/>
                    <path style="fill:#F0F0F0;" d="M401.553,274.441c-31.275-4.873-60.459,3.567-83.166,20.309c19.115-5.666,40.286-6.537,62.31-0.222
c40.038,11.486,70.963,45.261,78.285,86.263c7.987,44.74-11.11,85.658-43.503,109.534c49.681-14.729,84.932-63.309,78.754-118.946
C488.789,322.398,450.244,282.027,401.553,274.441z"/>
                    <path d="M264.533,477.867H25.6c-3.866,0-8.533-3.806-8.533-8.533v-25.6c0-4.71-3.814-8.533-8.533-8.533S0,439.023,0,443.733v25.6
c0,13.636,11.964,25.6,25.6,25.6h238.933c4.719,0,8.533-3.823,8.533-8.533S269.252,477.867,264.533,477.867z"/>
                    <path d="M8.533,384c4.719,0,8.533-3.823,8.533-8.533v-230.4c0-4.702,3.823-8.533,8.533-8.533h443.733
c4.71,0,8.533,3.831,8.533,8.533v119.467c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533V145.067
c0-14.114-11.486-25.6-25.6-25.6V76.8c0-14.114-11.486-25.6-25.6-25.6H261.692c-1.843,0-3.661-0.606-5.129-1.707L197.402,5.12
C193.007,1.818,187.554,0,182.042,0H51.2C37.086,0,25.6,11.486,25.6,25.6v93.867c-14.114,0-25.6,11.486-25.6,25.6v230.4
C0,380.177,3.814,384,8.533,384z M409.6,119.467H85.333v-8.533c0-4.702,3.823-8.533,8.533-8.533h307.2
c4.71,0,8.533,3.831,8.533,8.533V119.467z M42.667,25.6c0-4.702,3.823-8.533,8.533-8.533h130.842c1.843,0,3.661,0.606,5.129,1.707
l59.162,44.373c4.395,3.302,9.847,5.12,15.36,5.12h182.042c4.71,0,8.533,3.831,8.533,8.533v42.667h-25.6v-8.533
c0-14.114-11.486-25.6-25.6-25.6h-307.2c-14.114,0-25.6,11.486-25.6,25.6v8.533h-25.6V25.6z"/>
                    <path d="M384,256c-70.579,0-128,57.421-128,128s57.421,128,128,128s128-57.421,128-128S454.579,256,384,256z M273.067,384
c0-61.167,49.766-110.933,110.933-110.933c27.52,0,52.676,10.129,72.081,26.786L299.853,456.081
C283.196,436.676,273.067,411.52,273.067,384z M384,494.933c-27.52,0-52.676-10.129-72.081-26.786L468.156,311.91
c16.649,19.413,26.778,44.57,26.778,72.09C494.933,445.167,445.167,494.933,384,494.933z"/>
                    <path d="M102.4,59.733c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533H68.267c-4.719,0-8.533,3.823-8.533,8.533
s3.814,8.533,8.533,8.533H102.4z"/>
                    <path d="M170.667,59.733h8.533c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-8.533c-4.719,0-8.533,3.823-8.533,8.533
S165.948,59.733,170.667,59.733z"/>
                    <circle cx="136.533" cy="51.2" r="8.533"/>
                    <circle cx="8.533" cy="409.6" r="8.533"/>
                    <path d="M128,324.267c4.71,0,8.533-3.823,8.533-8.533c0-46.473,22.588-70.221,30.976-78.635c3.738,1.075,7.612,1.835,11.691,1.835
c4.079,0,7.953-0.759,11.691-1.835c8.388,8.414,30.976,32.162,30.976,78.635c0,4.71,3.823,8.533,8.533,8.533
s8.533-3.823,8.533-8.533c0-47.787-21.018-74.863-32.375-86.997c9.276-7.834,15.309-19.405,15.309-32.469
c0-23.526-19.14-42.667-42.667-42.667s-42.667,19.14-42.667,42.667c0,13.065,6.033,24.636,15.309,32.469
c-11.358,12.134-32.375,39.211-32.375,86.997C119.467,320.444,123.29,324.267,128,324.267z M179.2,170.667
c14.114,0,25.6,11.486,25.6,25.6c0,14.114-11.486,25.6-25.6,25.6c-14.114,0-25.6-11.486-25.6-25.6
C153.6,182.153,165.086,170.667,179.2,170.667z"/>
                    <path d="M281.6,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533
c0-7.68,0.043-13.278,5.683-19.166c3.575,1.28,7.373,2.099,11.383,2.099s7.808-0.819,11.383-2.099
c5.641,5.888,5.683,11.486,5.683,19.166c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
c5.265-6.007,8.567-13.773,8.567-22.374C315.733,177.442,300.425,162.133,281.6,162.133z M281.6,213.333
c-9.412,0-17.067-7.654-17.067-17.067S272.188,179.2,281.6,179.2c9.412,0,17.067,7.654,17.067,17.067S291.012,213.333,281.6,213.333
z"/>
                    <path d="M76.8,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-7.68,0.043-13.278,5.683-19.166
c3.575,1.28,7.373,2.099,11.383,2.099c4.011,0,7.808-0.819,11.383-2.099c5.641,5.888,5.683,11.486,5.683,19.166
c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
c5.265-6.007,8.567-13.773,8.567-22.374C110.933,177.442,95.625,162.133,76.8,162.133z M76.8,213.333
c-9.412,0-17.067-7.654-17.067-17.067S67.388,179.2,76.8,179.2s17.067,7.654,17.067,17.067S86.212,213.333,76.8,213.333z"/>
                    <g>
<path style="fill:#CC004E;" d="M300.629,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133s7.851-0.853,11.349-2.133c5.717,5.888,5.717,11.52,5.717,19.2
C298.667,249.515,299.435,251.477,300.629,252.928z"/>
                      <circle style="fill:#CC004E;" cx="281.6" cy="196.267" r="17.067"/>
</g>
                    <g>
<path style="fill:#0F7BE4;" d="M229.12,324.181c-11.008,34.219-88.832,34.219-99.84,0c4.096-0.683,7.253-4.181,7.253-8.448
c0-46.507,22.613-70.229,30.976-78.677c3.755,1.109,7.595,1.877,11.691,1.877s7.936-0.768,11.691-1.877
c8.363,8.448,30.976,32.171,30.976,78.677C221.867,320,225.024,323.499,229.12,324.181z"/>
                      <circle style="fill:#0F7BE4;" cx="179.2" cy="196.267" r="25.6"/>
</g>
                    <path style="fill:#FDB50D;" d="M409.6,110.933v8.533H85.333v-8.533c0-4.693,3.84-8.533,8.533-8.533h307.2
C405.76,102.4,409.6,106.24,409.6,110.933z"/>
                    <path style="fill:#AFAFAF;" d="M477.867,264.533V145.067c0-4.693-3.84-8.533-8.533-8.533H25.6c-4.693,0-8.533,3.84-8.533,8.533
v230.4c0,4.693-3.84,8.533-8.533,8.533v17.067c4.693,0,8.533,3.84,8.533,8.533c0,4.693-3.84,8.533-8.533,8.533V435.2
c4.693,0,8.533,3.84,8.533,8.533v25.6c0,4.693,4.693,8.533,8.533,8.533h238.933c4.693,0,8.533,3.84,8.533,8.533h34.219
C276.139,463.019,256,425.813,256,384c0-70.571,57.429-128,128-128c41.813,0,79.019,20.139,102.4,51.285v-34.219
C481.707,273.067,477.867,269.227,477.867,264.533z M110.933,247.467c0,4.693-3.84,8.533-8.533,8.533
c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C56.491,254.635,54.016,256,51.2,256
c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
c0-18.859,15.275-34.133,34.133-34.133s34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
C110.848,228.779,110.933,239.104,110.933,247.467z M230.4,324.267c-0.427,0-0.853,0-1.28-0.085
c-11.008,34.219-88.832,34.219-99.84,0c-0.427,0.085-0.853,0.085-1.28,0.085c-4.693,0-8.533-3.84-8.533-8.533
c0-47.787,20.992-74.837,32.341-87.04c-9.216-7.765-15.275-19.371-15.275-32.427c0-23.552,19.115-42.667,42.667-42.667
s42.667,19.115,42.667,42.667c0,13.056-6.059,24.661-15.275,32.427c11.349,12.203,32.341,39.253,32.341,87.04
C238.933,320.427,235.093,324.267,230.4,324.267z M315.733,247.467c0,4.693-3.84,8.533-8.533,8.533
c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C261.291,254.635,258.816,256,256,256
c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
c0-18.859,15.275-34.133,34.133-34.133c18.859,0,34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
C315.648,228.779,315.733,239.104,315.733,247.467z"/>
                    <g>
<circle style="fill:#CC004E;" cx="76.8" cy="196.267" r="17.067"/>
                      <path style="fill:#CC004E;" d="M95.829,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133c4.011,0,7.851-0.853,11.349-2.133
c5.717,5.888,5.717,11.52,5.717,19.2C93.867,249.515,94.635,251.477,95.829,252.928z"/>
</g>
                    <path style="fill:#7E939E;" d="M477.867,264.448V144.981c0-4.693-3.84-8.533-8.533-8.533h-25.6v111.019
c-12.117-5.12-25.344-8.107-39.168-8.533c-1.195-0.085-2.304-0.085-3.499-0.085c-15.104,0-29.525,3.072-42.667,8.533h-0.085
c-13.653,5.717-25.941,14.08-36.096,24.491c5.035-2.816,10.325-5.291,15.701-7.339c14.251-5.547,29.781-8.619,46.08-8.619
c21.589,0,41.899,5.376,59.733,14.848c16.811,8.789,31.317,21.333,42.667,36.437v-34.219
C481.707,272.981,477.867,269.141,477.867,264.448z"/>
                    <path style="fill:#F6981E;" d="M401.067,102.4h-34.133c4.693,0,8.533,3.84,8.533,8.533v8.533H409.6v-8.533
C409.6,106.24,405.76,102.4,401.067,102.4z"/>
                    <g>
<path style="fill:#215EFF;" d="M179.2,170.667c-0.137,0-0.273,0.043-0.41,0.043c10.103,3.43,17.476,12.919,17.476,24.149
c0,13.935-11.298,25.327-25.19,25.557c2.569,0.87,5.274,1.451,8.124,1.451c14.08,0,25.6-11.52,25.6-25.6
S193.28,170.667,179.2,170.667z"/>
                      <path style="fill:#215EFF;" d="M215.014,325.794c0,4.745,3.14,8.619,7.228,9.463c3.132-3.174,5.521-6.861,6.878-11.076
c-4.096-0.683-7.253-4.181-7.253-8.448c0-46.507-22.613-70.229-30.976-78.677c-2.022,0.597-4.079,1.067-6.178,1.399
C193.997,249.02,215.014,275.635,215.014,325.794z"/>
</g>
                    <g>
<path style="fill:#9B0045;" d="M291.337,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
C291.635,234.854,291.337,241.041,291.337,247.467z"/>
                      <path style="fill:#9B0045;" d="M282.223,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
C298.667,187.093,291.319,179.601,282.223,179.26z"/>
                      <path style="fill:#9B0045;" d="M86.537,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
C86.835,234.854,86.537,241.041,86.537,247.467z"/>
                      <path style="fill:#9B0045;" d="M77.423,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
C93.867,187.093,86.519,179.601,77.423,179.26z"/>
</g>
                    <path style="fill:#7E939E;" d="M443.733,68.267H261.717c-5.547,0-11.008-1.792-15.36-5.12l-59.221-44.373
c-1.451-1.109-3.243-1.707-5.12-1.707H51.2c-4.693,0-8.533,3.84-8.533,8.533v93.867h25.6v-8.533c0-14.08,11.52-25.6,25.6-25.6h307.2
c14.08,0,25.6,11.52,25.6,25.6v8.533h25.6V76.8C452.267,72.107,448.427,68.267,443.733,68.267z M102.4,59.733H68.267
c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533H102.4c4.693,0,8.533,3.84,8.533,8.533S107.093,59.733,102.4,59.733z
 M136.533,59.733c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533s8.533,3.84,8.533,8.533S141.227,59.733,136.533,59.733z
 M179.2,59.733h-8.533c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533h8.533c4.693,0,8.533,3.84,8.533,8.533
S183.893,59.733,179.2,59.733z"/>
</svg>
                  <span class="text-red-900 opacity-20 font-bold">No hay soportes agregados</span>
                </div>
                <div v-else class="w-full">
                  <div v-for="(support, index) in supports" :key="index" class="flex flex-wrap justify-between items-center p-1 w-full">
                    <div class="flex flex-wrap items-center">
                      <Icon
                        icon="iwwa:file-pdf"
                        class="text-4xl font-bold text-red-600 mr-4"
                      />
                      <div class="flex flex-col">
                        <span class="text-base font-semibold text-gray-500">Soporte {{ support.id }}</span>
                        <span class="text-sm text-gray-500">Descripción: {{ support.descripcion }}</span>
                      </div>
                    </div>
                    <Icon
                      icon="mdi:eye"
                      class="text-2xl font-bold text-blue-600 cursor-pointer"
                      @click="verSoporte(support.soporte)"
                      v-tippy="{ content: 'Ver soporte' }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <Button
      :icon="isEdit ? 'pi pi-pencil' : 'pi pi-send'"
      :label="isEdit ? 'Editar' : 'Guardar'"
      class="floating-button"
      :severity="isEdit ? 'warning' : 'success'"
      @click="onSubmit"
    />
  </div>

  <!--Defiinir modal con emit -->
  <ModalAgregarSoporte ref="modalAgregarSoporte" @updateListSupports="fetchSupportsBySolicitud" />
</template>

<style scoped>
.floating-button {
  position: fixed !important;
  bottom: 30px;
  right: 40px;
  z-index: 10050;
}
</style>
