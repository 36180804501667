import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_SOLICITUD_PERMISOS

export default class EmpleadoService {
  getEmpleado (id) {
    return http.get(`${baseUrl}/empleado/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  listarEmpleados (params) {
    return http.get(`${baseUrl}/empleado/listar`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarAreas () {
    return http.get(`${baseUrl}/area/listar`, {
      headers: {
        loading: true
      }
    })
  }

  listarCargos () {
    return http.get(`${baseUrl}/cargo/listar`, {
      headers: {
        loading: true
      }
    })
  }

  getAprobadoresComunes () {
    return http.get(`${baseUrl}/empleado/listar-aprobadores-comunes`, {
      headers: {
        loading: true
      }
    })
  }
}
