import http from '@/libs/http'
import { useStore } from '@/store'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_SOLICITUD_PERMISOS

export default class SoporteService {
  listarSoportesPorSolicitud (id) {
    return http.get(`${baseUrl}/soporte/listar/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  descargarSoporte (ruta) {
    const store = useStore()
    return new Promise((resolve) => {
      const url = `${baseUrl}/soporte/descargar`
      const token = store.getters['auth/getToken']
      console.log('token', token)
      console.log('ruta', ruta)
      console.log('url', url)
      const res = http.getUri({ url, params: { ruta, token } })
      resolve(res)
    })
  }

  eliminarSoporte (id) {
    return http.delete(`${baseUrl}/soporte/eliminar/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
