<script setup>
import { ref, defineExpose, defineEmits, onMounted } from 'vue'
import FilePond from '../components/uploadFilePond/index.vue'

const displayModal = ref(false)
const solicitudId = ref()
const descriptionSupport = ref('')
const pondFile = ref(null)
const urlSave = ref(process.env.VUE_APP_MICROSERVICE_API_SOLICITUD_PERMISOS)
const emit = defineEmits(['updateListSupports'])

// Funcion para abrir el modal
const openModal = (id) => {
  displayModal.value = true
  descriptionSupport.value = null
  solicitudId.value = id
}

/* Cerrar el modal */
const closeModalSupports = () => {
  displayModal.value = false
  descriptionSupport.value = null
}

/* Funcion para subir los archivos del componente filepond */
const submitSupport = () => {
  pondFile.value.onSubmitSupport().then(async () => {
    await pondFile.value.onRemoveSupport()
    descriptionSupport.value = null
    displayModal.value = false
    // Emitir evento para actualizar la lista de soportes
    emit('updateListSupports', solicitudId.value)
  })
}

// Se expone la funcion para abrir el modal, para que pueda ser llamado desde el componente padre
defineExpose({
  openModal
})

// Hook onMounted
onMounted(() => {
  console.log('onMounted, modal agregar soportes...')
})

</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '60vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Agregar Soporte a la solicitud #${solicitudId}` }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="grid gap-4 bg-white w-full sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xxl:grid-cols-5">
      <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
        <p class="mb-4">
          Cargue del soporte:
        </p>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <FilePond
              ref="pondFile"
              :solicitudId="solicitudId"
              :description="descriptionSupport"
              :url="`${urlSave}/soporte`"
            />
          </div>
          <div class="p-field p-col-12 p-md-12">
            <label for="name">Descripción:</label>
            <Textarea
              v-model="descriptionSupport"
              id="description"
              :autoResize="true"
              rows="2"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalSupports" class="p-button-xs p-button-danger"/>
      <Button label="Guardar" icon="pi pi-check" @click="submitSupport()" class="p-button-xs p-button-success" autofocus />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
