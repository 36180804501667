import http from '@/libs/http'
import { useStore } from '@/store'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_SOLICITUD_PERMISOS

export default class SolicitudService {
  crearSolicitud (body) {
    return http.post(`${baseUrl}/solicitud`, body, {
      headers: {
        loading: true
      }
    })
  }

  getSolicitud (id) {
    return http.get(`${baseUrl}/solicitud/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  editarSolicitud (id, body) {
    return http.put(`${baseUrl}/solicitud/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  eliminarSolicitud (id) {
    return http.delete(`${baseUrl}/solicitud/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  listarSolicitudes (params) {
    return http.get(`${baseUrl}/solicitud/listar`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarMotivos () {
    return http.get(`${baseUrl}/motivo/listar`, {
      headers: {
        loading: true
      }
    })
  }

  crearAprobacion (body) {
    return http.post(`${baseUrl}/solicitud/aprobacion`, body, {
      headers: {
        loading: true
      }
    })
  }

  crearChequeo (body) {
    return http.post(`${baseUrl}/solicitud/chequeo`, body, {
      headers: {
        loading: true
      }
    })
  }

  crearChequeoMasivo (body) {
    return http.post(`${baseUrl}/solicitud/chequeo-masivo`, body, {
      headers: {
        loading: true
      }
    })
  }

  restaurarSolicitud (body) {
    return http.post(`${baseUrl}/solicitud/restaurar`, body, {
      headers: {
        loading: true
      }
    })
  }

  enviarSolicitud (id, body) {
    return http.put(`${baseUrl}/solicitud/enviar/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  descargarPdf (id) {
    const store = useStore()
    return new Promise((resolve) => {
      const url = `${baseUrl}/solicitud/generar-pdf/${id}`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { token } })
      resolve(res)
    })
  }

  exportarExcel (params) {
    const store = useStore()
    return new Promise((resolve) => {
      const url = `${baseUrl}/solicitud/exportar-excel`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { token, ...params } })
      resolve(res)
    })
  }
}
